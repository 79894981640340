import PlaySVG from '@public/icons/video-review-play.svg';
import { useState } from 'react';
import { Review } from '@/entities';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import VideoPopup from '@/shared/components/popups/VideoPopup/VideoPopup';
import Image from '@/shared/components/shared/Image';
import { useTypograf } from '@/shared/hooks/use-typograf';
import Responsive from '../Responsive';

interface Props {
    card: Review;
    isMobile: boolean;
}

function ReviewCard({ card, isMobile }: Props) {
    const tpName = useTypograf(card.name);
    const tpJob = useTypograf(card.job);
    const tpText = useTypograf(card.text);
    const { openPopup } = useSetOpenedPopupsState();

    const [activeVideo, setActiveVideo] = useState<string | null>(null);
    const VIDEO_POPUP_NAME = `review-card-popup-${card.name}`;
    const handleOpenPopup = (videoSrc: string) => {
        setActiveVideo(videoSrc);
        openPopup(VIDEO_POPUP_NAME);
    };

    return (
        <div className="reviews-slider-content">
            <div className="reviews-slider-content-top">
                {isMobile && card.mobileImg && (
                    <div className="reviews-slider-slide-inner-mobile">
                        <Responsive className="reviews-slider-img-wrapper-mobile">
                            <Image
                                className="reviews-slider-img"
                                src={card.mobileImg.src}
                                alt={card.mobileImg.alt || ''}
                                title={card.mobileImg.title}
                                width={card.mobileImg.width}
                                height={card.mobileImg.height}
                                sizes="100vw"
                            />
                        </Responsive>
                    </div>
                )}
                <div className="reviews-slider-name-block-wrapper">
                    <div className="reviews-slider-name-block">
                        <div className="reviews-slider-name text-m">{tpName}</div>
                        <div className="reviews-slider-job text-xs">{tpJob}</div>
                    </div>
                    {card.video && card.video.length > 0 && card.video[0].src && (
                        <div className="reviews-slider-video-block" onClick={() => handleOpenPopup(card.video![0].src)}>
                            <PlaySVG className="video-review-logo" />
                            <div className="video-review-text text-xs">Видеоотзыв</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="reviews-slider-content-bottom">
                <p className="review-quote h3">“</p>
                <div className="text-s" dangerouslySetInnerHTML={{ __html: tpText }}></div>
            </div>
            {activeVideo && <VideoPopup name={VIDEO_POPUP_NAME} url={activeVideo} />}
        </div>
    );
}

export default ReviewCard;
