import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Review } from '@/entities';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import Image from '@/shared/components/shared/Image';
import Pagination from '@/shared/components/shared/Pagination';
import Responsive from '@/shared/components/shared/Responsive';
import ReviewCard from '@/shared/components/shared/ReviewCard';
import { useMounted } from '@/shared/hooks/use-mounted';
import { useOverflowing } from '@/shared/hooks/use-overflowing';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { Theme } from '@/shared/lib/types';

export type ReviewsSectionProps = {
    title: string;
    subtitle: string;
    cards: Review[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    reviewsSectionProps: ReviewsSectionProps;
};

function ReviewsSection({ reviewsSectionProps, ...props }: Props) {
    const [lastRealIndex, setLastRealIndex] = useState(0);
    const [currentRealIndex, setCurrentRealIndex] = useState(0);
    const numSlides = reviewsSectionProps.cards?.length;
    const device = useMediaQueryDeviceStateValue();
    const isMobile = device.includes('mobile');
    const tpTitle = useTypograf(reviewsSectionProps.title);
    const tpSubtitle = useTypograf(reviewsSectionProps.subtitle);
    const isMounted = useMounted();

    const sliderRef = useRef<SwiperRef>(null);
    const slider = useRef<HTMLDivElement | null>(null);
    const isInitialized = useOverflowing(slider);

    return (
        numSlides > 0 && (
            <section {...props} className={classNames('reviews-section', props.className)}>
                <div className="reviews-top wrapper">
                    <h2 className="reviews-title h3">{tpTitle}</h2>
                    <div className="reviews-description-block">
                        <div className="reviews-description text-s">{tpSubtitle}</div>
                    </div>
                </div>
                {isInitialized && isMounted ? (
                    <div className="reviews-bottom">
                        <Swiper
                            ref={sliderRef}
                            className="reviews-slider"
                            modules={[Navigation]}
                            navigation={{
                                prevEl: '.reviews-slider-prev-btn',
                                nextEl: '.reviews-slider-next-btn',
                            }}
                            speed={650}
                            breakpoints={{
                                320: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 8,
                                    slidesOffsetBefore: 16,
                                    slidesOffsetAfter: 16,
                                },
                                769: {
                                    slidesPerView: 'auto',
                                    spaceBetween: 12,
                                    slidesOffsetBefore: 22,
                                    slidesOffsetAfter: 22,
                                },
                                1201: {
                                    slidesPerView: 2.2,
                                    spaceBetween: 20,
                                    slidesOffsetBefore: 36,
                                    slidesOffsetAfter: 36,
                                },
                                1366: {
                                    slidesPerView: 2.2,
                                    spaceBetween: 16,
                                    slidesOffsetBefore: 70,
                                    slidesOffsetAfter: 70,
                                },
                            }}
                            onSlideChange={(swiper) => {
                                const newRealIndex = swiper.realIndex;
                                setLastRealIndex(currentRealIndex);
                                setCurrentRealIndex(newRealIndex);
                            }}
                        >
                            {reviewsSectionProps.cards.map((slide, i) => (
                                <SwiperSlide className="reviews-slider-slide" key={i}>
                                    {!isMobile && (
                                        <div className="reviews-slider-slide-inner">
                                            <Responsive className="reviews-slider-img-wrapper">
                                                <Image
                                                    className="reviews-slider-img"
                                                    src={slide.img.src}
                                                    alt={slide.img.alt || ''}
                                                    title={slide.img.title}
                                                    width={slide.img.width}
                                                    height={slide.img.height}
                                                    sizes="(max-width: 1199px) 20vw, 30vw"
                                                />
                                            </Responsive>
                                        </div>
                                    )}
                                    <ReviewCard card={slide} isMobile={isMobile} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <Pagination
                            numSlides={numSlides}
                            currentRealIndex={currentRealIndex}
                            lastRealIndex={lastRealIndex}
                            prevElClass="reviews-slider-prev-btn"
                            nextElClass="reviews-slider-next-btn"
                            wrapperClass="reviews-pagination-wrapper wrapper"
                            hidden={!isInitialized}
                        />
                    </div>
                ) : (
                    <div className="wrapper reviews__list swiper__wrapper" ref={slider}>
                        {reviewsSectionProps.cards.map((slide, i) => (
                            <div className="swiper-slide reviews-slider-slide" key={i}>
                                {!isMobile && (
                                    <div className="reviews-slider-slide-inner">
                                        <Responsive className="reviews-slider-img-wrapper">
                                            <Image
                                                className="reviews-slider-img"
                                                src={slide.img.src}
                                                alt={slide.img.alt || ''}
                                                title={slide.img.title}
                                                width={slide.img.width}
                                                height={slide.img.height}
                                            />
                                        </Responsive>
                                    </div>
                                )}
                                <ReviewCard card={slide} isMobile={isMobile} />
                            </div>
                        ))}
                    </div>
                )}
            </section>
        )
    );
}

export default ReviewsSection;
