import CloseSVG from '@public/icons/close-btn.svg';
import LoaderSVG from '@public/icons/loader.svg';
import { useOpenedPopupsValue, useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import Popup from '@/shared/components/shared/Popup';
import { useDebounce } from '@/shared/hooks/use-debounce';

interface Props {
    url: string;
    name: string;
}

const VideoPopup = ({ name, url }: Props) => {
    const openedPopups = useOpenedPopupsValue();
    const isVideoPopupOpen = openedPopups.includes(name);
    const debouncedVideoVisibility = useDebounce(isVideoPopupOpen, 500);
    const { closePopup } = useSetOpenedPopupsState();

    return (
        <Popup name={name} data-popup-preset="default" className="video-popup" overlay={true}>
            <div className="video-popup__container">
                {(isVideoPopupOpen || debouncedVideoVisibility) && (
                    <video className="responsive__item" autoPlay src={url}></video>
                )}
                <button className="video-popup-close-btn" onClick={() => closePopup(name)}>
                    <CloseSVG />
                </button>
            </div>
            <div className="video-popup__container-loader">
                <LoaderSVG />
            </div>
        </Popup>
    );
};

export default VideoPopup;
