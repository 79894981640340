import { m as motion, useAnimation } from 'framer-motion';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import Button from '@/shared/components/shared/Button';
import Image from '@/shared/components/shared/Image';
import Pagination from '@/shared/components/shared/Pagination';
import Responsive from '@/shared/components/shared/Responsive';
import { useMounted } from '@/shared/hooks/use-mounted';
import { useOverflowing } from '@/shared/hooks/use-overflowing';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { ImageShape, Theme } from '@/shared/lib/types';
import { easeInOutSine } from '@/shared/lib/utils/easings';

export type HowItWasSectionProps = {
    slider: { images: ImageShape[] };
    title: string;
    description: string;
    link?: {
        href: string;
        name: string;
    } | null;
};

type Props = React.HTMLAttributes<HTMLElement> & {
    howItWasSectionProps: HowItWasSectionProps;
    theme?: Theme;
};

export const SECTION_ID = 'howItWas';

function HowItWasSection({ howItWasSectionProps, theme }: Props) {
    const [currentRealIndex, setCurrentRealIndex] = useState(0);
    const [lastRealIndex, setLastRealIndex] = useState(0);
    const tpTitle = useTypograf(howItWasSectionProps.title);
    const tpDescription = useTypograf(howItWasSectionProps.description);
    const numSlides = howItWasSectionProps.slider.images.length;
    const [direction, setDirection] = useState(0);
    const controls = useAnimation();

    const sliderRef = useRef<SwiperRef>();
    const slider = useRef<HTMLDivElement | null>(null);
    const isInitialized = useOverflowing(slider);
    const isMounted = useMounted();
    const [loop, setLoop] = useState(false);

    useEffect(() => {
        setLoop(true);
    }, []);

    useEffect(() => {
        sliderRef.current?.swiper.update();
    }, [loop]);

    useEffect(() => {
        if (currentRealIndex === 0 && lastRealIndex === numSlides - 1) {
            setDirection(1);
        } else if (currentRealIndex === numSlides - 1 && lastRealIndex === 0) {
            setDirection(-1);
        } else if (currentRealIndex > lastRealIndex) {
            setDirection(1);
        } else if (currentRealIndex < lastRealIndex) {
            setDirection(-1);
        }

        controls.start({
            x: direction > 0 ? [0, 30, 0] : [0, -30, 0],
            transition: {
                duration: 0.9,
                ease: easeInOutSine,
                delay: 0.1,
                stiffness: 0,
                damping: 0,
            },
        });
    }, [currentRealIndex, lastRealIndex, numSlides, direction, controls]);

    return (
        <section
            className="how-it-was-section js-bg-trigger-dark js-header-theme-trigger"
            data-offset="none"
            data-bg="#05164A"
            data-theme={theme}
            id={SECTION_ID}
        >
            <div className="how-it-was-top wrapper">
                <h2 className="how-it-was-title h3">{tpTitle}</h2>
                <div className="how-it-was-description-block">
                    <h3 className="how-it-was-description text-s">{tpDescription}</h3>
                    {howItWasSectionProps.link && howItWasSectionProps.link.href && (
                        <Button
                            tag="a"
                            target="_blank"
                            rel="noreferrer nofollow"
                            href={howItWasSectionProps.link.href}
                            variant={`outline-${theme || 'dark'}`}
                        >
                            {howItWasSectionProps.link.name}
                        </Button>
                    )}
                </div>
            </div>
            {isInitialized && isMounted ? (
                <div className="how-it-was-bottom">
                    <Swiper
                        className="how-it-was-slider"
                        ref={sliderRef as MutableRefObject<SwiperRef>}
                        modules={[Navigation]}
                        centeredSlides
                        speed={800}
                        loop={loop}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.1,
                                spaceBetween: 8,
                            },
                            769: {
                                slidesPerView: 2,
                                spaceBetween: 12,
                            },
                            1201: {
                                slidesPerView: 2.6,
                                spaceBetween: 20,
                            },
                            1921: {
                                slidesPerView: 3.5,
                                spaceBetween: 20,
                            },
                        }}
                        onRealIndexChange={(swiper) => {
                            const newRealIndex = swiper.realIndex;
                            setLastRealIndex(currentRealIndex);
                            setCurrentRealIndex(newRealIndex);
                        }}
                    >
                        {howItWasSectionProps.slider.images.map((slide, i) => {
                            return (
                                <SwiperSlide className="how-it-was-slider-slide" key={i}>
                                    <Responsive className="how-it-was-slider-img">
                                        <motion.div animate={controls} className="how-it-was-slider-img-inner">
                                            <Image
                                                className="responsive__item"
                                                src={slide.src}
                                                alt={slide.alt || ''}
                                                title={slide.title}
                                                width={slide.width}
                                                height={slide.height}
                                                sizes="(max-width: 1199px) 100vw, 30vw"
                                            />
                                        </motion.div>
                                    </Responsive>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    {isInitialized && (
                        <Pagination
                            numSlides={numSlides}
                            currentRealIndex={currentRealIndex}
                            lastRealIndex={lastRealIndex}
                            prevElClass="slider-prev-btn"
                            nextElClass="slider-next-btn"
                            wrapperClass="how-it-was-pagination pagination-wrapper wrapper"
                            onPrevClick={() => {
                                sliderRef.current?.swiper.slidePrev();
                            }}
                            onNextClick={() => {
                                sliderRef.current?.swiper.slideNext();
                            }}
                        />
                    )}
                </div>
            ) : (
                <div className="how-it-was__list wrapper swiper__wrapper" ref={slider}>
                    {howItWasSectionProps.slider.images.map((slide, i) => {
                        return (
                            <div className="how-it-was-slider-slide" key={i}>
                                <Responsive>
                                    <Image
                                        className="how-it-was-slider-img responsive__item"
                                        src={slide.src}
                                        alt={slide.alt || ''}
                                        title={slide.title}
                                        width={slide.width}
                                        height={slide.height}
                                        sizes="(max-width: 1199px) 100vw, 30vw"
                                    />
                                </Responsive>
                            </div>
                        );
                    })}
                </div>
            )}
        </section>
    );
}

export default HowItWasSection;
